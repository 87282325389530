import React from 'react';
import './Wallet.scss';
import l1 from "../../Assets/l1.png";
import l2 from "../../Assets/l2.png";
import l3 from "../../Assets/l3.png";
import l4 from "../../Assets/l4.png";
import l5 from "../../Assets/l5.png";

// Array 1
const data1 = [
  { img: l1, name: 'Bitcoin', profit: '3.99%', investment: '$12000' },
  { img: l2, name: 'Ethereum', profit: '2.75%', investment: '$8000' },
  { img: l3, name: 'Litecoin', profit: '1.50%', investment: '$5000' },
  { img: l4, name: 'Ripple', profit: '4.20%', investment: '$3000' },
  { img: l5, name: 'Cardano', profit: '3.00%', investment: '$7000' }
];

// Array 2
const data2 = [
  { img: l5, name: 'Polkadot', profit: '5.00%', investment: '$15000' },
  { img: l4, name: 'Chainlink', profit: '4.50%', investment: '$9500' },
  { img: l3, name: 'Stellar', profit: '2.20%', investment: '$6000' },
  { img: l2, name: 'Uniswap', profit: '3.75%', investment: '$4000' },
  { img: l1, name: 'Solana', profit: '6.00%', investment: '$8000' }
];

export const Wallet = () => {
  return (
    <div className='wallet'>
      <div>
        <h1>Your All-In-One <span>Global Payment Gateway</span></h1>
        <p>
          Receive multiple cryptocurrencies through our secure and versatile wallet, designed for all your digital asset needs.
        </p>

        {/* Box Wrapper 1 */}
        <div className="box-wrapper box1">
          {data1.map((item, index) => (
            <div key={index} className="box">
              <div className="image">
                <img src={item.img} alt={`Logo for ${item.name}`} />
              </div>
              <div className="name">
                {item.name}
                <p>Profit <span>{item.profit}</span></p>
              </div>
              <div className="investment">
                Investment <p>{item.investment}</p> 
              </div>
            </div>
          ))}
        </div>

        {/* Box Wrapper 2 */}
        <div className="box-wrapper box2">
          {data2.map((item, index) => (
            <div key={index} className="box">
              <div className="image">
                <img src={item.img} alt={`Logo for ${item.name}`} />
              </div>
              <div className="name">
                {item.name}
                <p>Profit <span>{item.profit}</span></p>
              </div>
              <div className="investment">
                Investment <p>{item.investment}</p> 
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
