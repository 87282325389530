import React from 'react'
import './Footer.scss'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook } from '@fortawesome/free-brands-svg-icons';

export const Footer = () => {
  return (
    <div className='footer'>

        <div className="icons"><i className="fa-brands fa-facebook"></i><i className="fa-brands fa-x-twitter"></i><i className="fa-brands fa-instagram"></i><i className="fa-brands fa-linkedin"></i></div>
        <div className="terms"><span>Terms & Conditions</span><span>Privacy Policy</span> <span>Legal</span><span>MSB Licence</span></div>
        <p>Copyrights © 2024. All rights reserved     Powered by BOF Bank, Canada</p>
    </div>
  )
}
