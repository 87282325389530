import React from 'react'
import './Touch.scss'

export const Touch = () => {
  return (
    <div className='touch'>
<h3>We’re Here for You </h3>
<p>Reach Out to Us Anytime </p>

<div>

    <form action="">

        <input type="text" placeholder='Name' required/>
        <input type="emil" placeholder='Email' required />
        <input type="phone" placeholder='Mobile Number' required />

        <button>Submit</button>
    </form>
</div>

    </div>
  )
}
