import React from "react";
import "./Users.scss";

export const Users = () => {
  return (
    <div className="users">
      <div><h3>
      Connecting Users <span>Globally </span>
      </h3>
      <p>
      With users spanning continents, FloxyPay Global is truly an international solution for digital payments and transactions.
      </p>

      <div className="data">
        <div>
          <h6>98M+</h6>
          <p>Happy Costumers</p>
        </div>
        <div>
          <h6>$249M+</h6>
          <p>Total Transactions</p>
        </div>
      </div></div>
    </div>
  );
};
