import React from "react";
import "./PaymentSolution.scss";
import withdraw from "../../Assets/withdraw.png";
import encrypt from "../../Assets/Encryption.png";
import transparent from "../../Assets/transparent.png";
import payment from "../../Assets/payment.png";
import check from "../../Assets/check.png";

export const PaymentSolution = () => {
  return (
    <div className="payment-solution">
      <div>
        <h3>Secured Payment Solutions for a Safer Experience</h3>
        <div className="cards">
          <div className="card">
            <div>
              <img src={transparent} alt="" />
            </div>
            <h5>Advanced Encryption </h5>
            <p>
              With our state-of-the-art encryption technology, we safeguard your
              personal and transactional data with the highest level of
              security.
            </p>
          </div>
          <div className="card">
            <div>
              <img src={encrypt} alt="" />
            </div>
            <h5>No Code Integration</h5>
            <p>
              Our secure payment gateway requires no coding and provides an
              efficient setup.
            </p>
          </div>
          <div className="card">
            <div>
              <img src={withdraw} alt="" />
            </div>
            <h5>Quick Payout</h5>
            <p>
              Experience fast and efficient payouts to make sure your funds are
              available anytime and anywhere you need.
            </p>
          </div>
        </div>
        <div className="payment">
          <div className="left">
            <img src={payment} alt="" />
          </div>
          <div className="right">
            <h3>Experience Unmatched Security </h3>
            <p>
              Seamless, secure, and efficient transactions for users worldwide.{" "}
            
              With cutting-edge technology and robust encryption, we ensure your
              digital assets are protected at every step.
            </p>
            <div className="check">
              <p>
                <img src={check} alt="" /> Instant Settlements{" "}
              </p>
              <p>
                <img src={check} alt="" /> Secure and Transparent
              </p>
              <p>
                <img src={check} alt="" /> Global Accessibility{" "}
              </p>
            </div>
            <button>Get Started</button>
          </div>
        </div>
      </div>
    </div>
  );
};
