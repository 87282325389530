import React from "react";
import "./Currencies.scss";

const Currencies = () => {
  return (
    <div className="currencies">
      <div>
        <div className="left">
          <h4>Currencies we support</h4>
          <table>
            <tr>
              <td>$</td>
              <td>United State Dollar</td>
              <td>USD</td>
            </tr>
            <tr>
              <td>B</td>
              <td>Thai Baht</td>
              <td>THB</td>
            </tr>
            <tr>
              <td>Tk</td>
              <td>Bangladeshi Taka</td>
              <td>BDT</td>
            </tr>
            <tr>
              <td>R</td>
              <td>South African Rand</td>
              <td>ZAR</td>
            </tr>
            <tr>
              <td>فلس</td>
              <td>United Arab Emirates Dirham</td>
              <td>AED</td>
            </tr>
            <tr>
              <td>PKRs</td>
              <td> Pakistani Rupee</td>
              <td>PKR</td>
            </tr>
          </table>
        </div>
        <div className="right">
            <h2>Universal Currency Compatibility </h2>
            <p>From Dollar to Dirham, From Thai Baht to Bangladeshi Taka, our platform supports a vast array of currencies, offering you the flexibility to transact globally with utmost security.

<br /><br />

Enjoy hassle-free payments with state-of-the-art security across multiple digital currencies.
</p>
<button>Get Started</button>
        </div>
      </div>
    </div>
  );
};

export default Currencies;
