import React from "react";
import "./Offices.scss";
import pin from "../../Assets/location.png";

export const Offices = () => {
  const office = [
    {
      address: `Office 121, KML bulding,Al meydan road, KML bulding, Dubai (UAE)
License No. 1073966
Registration Number 2017974`,
      city: "Dubai",
    },
    {
      city: "South Africa",
      address: `1312 17th Street Suite 1220 , Denver, Colorado 80202`,
    },
    {
      city: "Thailand",
      address: `No. 7, house 4, Winston churchill street, asokoro abuja, FCT, Nigeria`,
    },
    {
      city: "Bangladesh",
      address: `81-83 Campbell Street Surry Hills NSW 2010 Australia`,
    },
    {
      city: "Pakistan",
      address: `1312 17th Street Suite 1220 , Denver, Colorado 80202`,
    },
  ];

  return (
    <div className="offices">
      <div>
        <h1>We’re Everywhere</h1>
      <p>Our Offices Across the Globe </p>
        <div className="card-wrapper">
          {office.map((item,index)=>(
            <div key={index} className="card">
            <div>
              <img src={pin} alt="" />
            </div>
            <h5>{item.city}</h5>
            <p>
              {item.address}
            </p>
          </div>
          ))}
        </div>
      </div>
    </div>
  );
};
