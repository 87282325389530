import React from 'react'
import { HeroWrapper } from '../Components/HeroWrapper/HeroWrapper';
import Currencies from '../Components/Currencies/Currencies';
import { Users } from '../Components/Users/Users';
import { PaymentSolution } from '../Components/PaymentSolutions/PaymentSolution';
import { Wallet } from '../Components/Wallet/Wallet';
import { Touch } from '../Components/Touch/Touch';
import { Offices } from '../Components/Offices/Offices';
import { Footer } from '../Components/Footer/Footer';

export const Home = () => {
  return (
    <div>
          <HeroWrapper/>
     <Currencies/>
     <Users/>
     <PaymentSolution/>
     <Wallet/>
     <Touch/>
     <Offices/>
     <Footer/>


    </div>
  )
}
