import React from 'react'
import './HeroBanner.scss'

export const HeroBanner = () => {
  return (
    <div className='hero'>
<h1 class>Empowering  <span>Global Transactions</span> Seamlessly </h1>
<h4></h4>
<p>Integrate every fiat currency in the world, and unlock limitless possibilities — keep transacting with our quick, seamless, and secured payment gateway that assists from everywhere and anywhere in the world.</p>
<button>Get Started</button>
    </div>
  )
}
